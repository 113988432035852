
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Cylindrical1 from "@/components/widgets/charts/Cylindrical1.vue";
import Cylindrical2 from "@/components/widgets/charts/Cylindrical2.vue";
import OrderList from "@/ComponentsSystem/ProductLine/OrderList.vue";
import setting from "@/data/setting.json";
import axios from "axios";
import moment from "moment";

export default defineComponent({
  name: "overview",
  components: {
    Cylindrical1,
    Cylindrical2,
    OrderList,
  },
  data(): {
    setting: any;
    setting2: any;
    Auth: any;
    Name: any;
    ApiURL: string;
    Param: any;
    Step: number;
    Chart: number;
    Data: any;
    MachineList: Array<any>;
    MachineList2: Array<any>;
    MachineList3: Array<any>;
    MachineList4: Array<any>;
    List2: Array<any>;
    Type: string;
    Today: any;
    series: Array<any>;
    chartOptions: any;
    humidity: any;
    temperature: any;
    watertemp: any;
    Value: any;
  } {
    return {
      setting: setting,
      setting2: setting,
      Auth: {},
      Name: "",
      ApiURL: "https://ah.artinuro.com:8443/",
      Param: {
        Paging: {
          pageSize: 10,
          pageNumber: 1,
        },
        Search: {},
      },
      Step: 0,
      Chart: 0,
      Data: {},
      MachineList: [
        {
          Name: "研-17",
          Status: "1",
          Number: "RT664-179-00",
          Count: "",
          data: {},
        },
      ],
      MachineList2: [
        {
          Name: "研-16",
          Status: "1",
          Number: "ATV59-7211",
          Count: "519",
        },
      ],
      MachineList3: [
        {
          Name: "研-18",
          Status: "1",
          Number: "2TS-E6151",
          Count: "683",
        },
      ],
      MachineList4: [
        {
          Name: "研-19",
          Status: "1",
          Number: "ANT-127903",
          Count: "76",
        },
      ],
      List2: [
        {
          Number: "365522",
          MaterialNumber: "RT663-001-00",
          Count: 160,
          Start: "2024/04/03",
          Finish: "2024/04/08",
        },
        {
          Number: "368406",
          MaterialNumber: "RT663-001-10",
          Count: 1967,
          Start: "2024/04/02",
          Finish: "2024/04/07",
        },
        {
          Number: "368522",
          MaterialNumber: "RT663-001-20",
          Count: 2725,
          Start: "2024/04/01",
          Finish: "2024/04/05",
        },
        {
          Number: "367413",
          MaterialNumber: "RT663-001-30",
          Count: 123,
          Start: "2024/04/03",
          Finish: "2024/04/08",
        },
        {
          Number: "332144",
          MaterialNumber: "RT663-001-40",
          Count: 34251,
          Start: "2024/04/03",
          Finish: "2024/04/08",
        },
      ],
      Type: "",
      Today: new Date(),
      series: [
        {
          name: "Ｘ",
          type: "line",
          data: [],
        },
        {
          name: "Ｙ",
          type: "line",
          data: [],
        },
        {
          name: "Ｚ",
          type: "line",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "line",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [2, 2, 2],
        },
        title: {
          // text: "溫度,濕度,液溫",
          align: "left",
          offsetX: 110,
        },
        xaxis: {
          categories: [],
          tickAmount: 5,
        },
        yaxis: [
          {
            // tickAmount: 6,
            min: () => {
              return Math.min(...this.series[0].data) - 0.05;
            },
            max: () => {
              return Math.max(...this.series[0].data) + 0.05;
            },
            seriesName: "X",
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#008FFB",
            },
            labels: {
              style: {
                colors: "#008FFB",
              },
            },
            title: {
              text: "X",
              style: {
                color: "#008FFB",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            min: () => {
              return Math.min(...this.series[1].data) - 0.05;
            },
            max: () => {
              return Math.max(...this.series[1].data) + 0.05;
            },
            seriesName: "Y",
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#00E396",
            },
            labels: {
              style: {
                colors: "#00E396",
              },
            },
            title: {
              text: "Y",
              style: {
                color: "#00E396",
              },
            },
          },
          {
            min: () => {
              return Math.min(...this.series[2].data) - 0.05;
            },
            max: () => {
              return Math.max(...this.series[2].data) + 0.05;
            },
            seriesName: "Z",
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FEB019",
            },
            labels: {
              style: {
                colors: "#FEB019",
              },
            },
            title: {
              text: "Z",
              style: {
                color: "#FEB019",
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      humidity: "",
      temperature: "",
      watertemp: "",
      Value: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("機台儀表板", ["生產線即時監控"]);
    });
  },
  watch: {},
  methods: {
    DayFormatter(value) {
      return moment(value).format("YYYYMMDD");
    },
    HourFormatter(value) {
      return moment(value).format("HH");
    },
    MinFormatter(value) {
      return moment(value).format("mm");
    },
    DateFormatter(value) {
      return moment(value).format("HH:mm:ss");
    },
    Detail() {
      this.Step = 1;
    },
    async Init() {
      let str: any;
      str = localStorage.getItem("Auth");
      this.Auth = JSON.parse(str);
      this.Name = this.Auth.Name;
      switch (this.Name) {
        case "Root":
        case "超正":
        case "升大":
        case "國敏":
          this.Value = 0;
          await this.GetSensor(this.Value, new Date());
          break;
        case "上翊":
          this.Value = 1;
          await this.GetSensor(this.Value, new Date());
          break;
      }
      document.getElementsByClassName("sensor")[0].classList.add("active");
      this.Step = 0;
      this.Today = new Date("2024/06/28 08:00");
      // this.MachineList2.
      if (sessionStorage.getItem("M16")) {
        this.MachineList2[0].Count = Number(sessionStorage.getItem("M16"));
        this.MachineList3[0].Count = Number(sessionStorage.getItem("M18"));
        this.MachineList4[0].Count = Number(sessionStorage.getItem("M19"));
      } else {
        sessionStorage.setItem("M16", this.MachineList2[0].Count);
        sessionStorage.setItem("M18", this.MachineList3[0].Count);
        sessionStorage.setItem("M19", this.MachineList4[0].Count);
      }
      await this.RandomGrind();

      await this.GetGrind();
      setInterval(() => {
        this.GetGrind();
        this.GetSensor(this.Value, new Date());
      }, 1200);
    },
    async Exchange(val) {
      this.Value = val;
      this.Chart = 0;
      this.series[0].data = [];
      this.series[1].data = [];
      this.series[2].data = [];
      this.chartOptions.xaxis.categories = [];
      await this.GetSensor(val, new Date());
      // axios
      //   .get(this.ApiURL + "Sensor/" + this.setting[this.Value].Sensor)
      //   .then(res => {
      //     if (this.series[0].data.length >= 600) {
      //       this.series[0].data.splice(0, 1);
      //       this.series[1].data.splice(0, 1);
      //       this.series[2].data.splice(0, 1);
      //       this.chartOptions.xaxis.categories.splice(0, 1);
      //     }
      //     this.series[0].data.push(res.data.x);
      //     this.series[1].data.push(res.data.y);
      //     this.series[2].data.push(res.data.z);
      //     this.humidity = res.data.humidity;
      //     this.temperature = res.data.temperature;
      //     this.watertemp = res.data.watertemp;
      //     this.chartOptions.xaxis.categories.push(
      //       this.DateFormatter(res.data.time)
      //     );
      //     this.MinMax();
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    async GetSensor(val, date) {
      this.Value = val;
      await axios
        .get(this.ApiURL + "Sensor/" + this.setting[this.Value].Sensor)
        .then(res => {
          this.Chart = 1;
          if (this.series[0].data.length >= 600) {
            this.series[0].data.splice(0, 1);
            this.series[1].data.splice(0, 1);
            this.series[2].data.splice(0, 1);
            this.chartOptions.xaxis.categories.splice(0, 1);
          }
          this.chartOptions.xaxis.categories.push(this.DateFormatter(date));
          this.series[0].data.push(res.data.x);
          this.series[1].data.push(res.data.y);
          this.series[2].data.push(res.data.z);
          this.humidity = res.data.humidity;
          this.temperature = res.data.temperature;
          this.watertemp = res.data.watertemp;
          this.MinMax();
        })
        .catch(err => {
          console.log(err);
        });
    },
    async MinMax() {
      this.chartOptions.yaxis[0].min = () => {
        return Math.min(...this.series[0].data) - 0.1;
      };
      this.chartOptions.yaxis[0].max = () => {
        return Math.max(...this.series[0].data) + 0.1;
      };
      this.chartOptions.yaxis[1].min = () => {
        return Math.min(...this.series[1].data) - 0.1;
      };
      this.chartOptions.yaxis[1].max = () => {
        return Math.max(...this.series[1].data) + 0.1;
      };
      this.chartOptions.yaxis[2].min = () => {
        return Math.min(...this.series[2].data) - 0.1;
      };
      this.chartOptions.yaxis[2].max = () => {
        return Math.max(...this.series[2].data) + 0.1;
      };
    },
    async GetGrind() {
      axios
        .get(this.ApiURL + "Grind/10")
        .then(res => {
          this.MachineList[0].data = res.data;
          this.MachineList[0].Count = res.data.當批加工數量;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async RandomGrind() {
      setInterval(() => {
        this.MachineList2[0].Count = Number(this.MachineList2[0].Count) + 1;
        this.MachineList3[0].Count = Number(this.MachineList3[0].Count) + 1;
        this.MachineList4[0].Count = Number(this.MachineList4[0].Count) + 1;
        sessionStorage.setItem("M16", this.MachineList2[0].Count);
        sessionStorage.setItem("M18", this.MachineList3[0].Count);
        sessionStorage.setItem("M19", this.MachineList4[0].Count);
      }, this.getRandom(8000, 10000));
    },
    getRandom(min, max) {
      const floatRandom = Math.random();
      const difference = max - min;
      const random = Math.round(difference * floatRandom);
      const randomWithinRange = random + min;
      return randomWithinRange;
    },
  },
  async created() {
    let str: any;
    str = localStorage.getItem("Auth");
    this.Auth = JSON.parse(str);
    this.Name = this.Auth.Name;
    switch (this.Name) {
      case "Root":
        break;
      case "上翊":
        this.setting = setting.filter(item => {
          return (
            item.Name == "上翊1" || item.Name == "上翊2" || item.Name == "上翊3"
          );
        });

        break;
      case "超正":
        this.setting = setting.filter(item => {
          return item.Name == "超正";
        });

        break;
      case "升大":
        this.setting = setting.filter(item => {
          return item.Name == "升大";
        });
        break;
      case "國敏":
        this.setting = setting.filter(item => {
          return item.Name == "國敏";
        });
        break;
    }

    await this.Init();
    setTimeout(() => {
      this.Chart = 1;
    }, 1500);
  },
});
