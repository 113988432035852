
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
  },
  components: {},
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-600");
    const dangerColor = getCSSVariableValue("--bs-danger");

    const options = {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2024-02-10T00:00:00.000Z",
          "2024-02-10T01:30:00.000Z",
          "2024-02-10T02:30:00.000Z",
          "2024-02-10T03:30:00.000Z",
          "2024-02-10T04:30:00.000Z",
          "2024-02-10T05:30:00.000Z",
          "2024-02-10T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    };

    const series = [
      {
        name: "標準工時",
        data: [100, 100, 100, 100, 100, 100, 100],
      },
      {
        name: "實際工時",
        data: [102, 95, 98, 82, 74, 52, 91],
      },
    ];
    return {
      options,
      series,
    };
  },
});
