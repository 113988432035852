
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
  },
  components: {},
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-600");
    const dangerColor = getCSSVariableValue("--bs-danger");

    const options = {
      chart: {
        type: "bar",
        height: 480,
        stacked: false,
        // stackType: "100%",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 0.7,
        colors: ["#fff"],
      },
      title: {
        text: "過去七天工時／產量",
      },
      xaxis: {
        categories: ["4/1", "4/2", "4/3", "4/4", "4/5", "4/6", "4/7"],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },

      yaxis: [
        {
          max: 24,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            style: {},
          },
          title: {
            text: "工時",
            style: {},
          },
        },
        {
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            style: {},
          },
          title: {
            text: "產量",
            style: {},
          },
        },
        // {
        //   title: {
        //     text: "工時",
        //   },
        //   labels: {
        //     style: {
        //       fontSize: "12px",
        //     },
        //   },
        // },
        // {
        //   title: {
        //     text: "產量",
        //   },
        //   labels: {
        //     style: {
        //       fontSize: "12px",
        //     },
        //   },
        // },
      ],
      tooltip: {
        y: [
          {
            formatter: function (val) {
              return "：" + val + "小時";
            },
          },
          {
            formatter: function (val) {
              return "：" + val;
            },
          },
        ],
        x: {
          formatter: function (val) {
            return "日期：" + val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
      colors: [baseColor, secondaryColor],
    };

    const series = [
      {
        name: "工時",
        data: [11, 12, 11, 10, 4, 7, 20],
      },
      {
        name: "產量",
        data: [53, 32, 33, 52, 13, 44, 32],
      },
    ];

    return {
      options,
      series,
    };
  },
});
