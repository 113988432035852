
import { defineComponent } from "vue";
import Area01 from "@/components/widgets/charts/Area01.vue";

export default defineComponent({
  name: "kt-widget-12",
  components: { Area01 },
  props: {
    widgetClasses: String,
  },
  data(): {
    Data: any;
    ProductType: string;
    DataNumber: string;
  } {
    return {
      Data: {},
      ProductType: "",
      DataNumber: "",
    };
  },
  setup() {
    const list = [
      {
        client: {
          Name: "50",
        },
        order: {
          Number: "1234",
          Itemnumber: "100",
          Date: "100",
        },
        shipping: {
          Date: "201223456",
        },
      },
      {
        client: {
          Name: "60",
        },
        order: {
          Number: "2234",
          Itemnumber: "101",
          Date: "102",
        },
        shipping: {
          Date: "101223456",
        },
      },
      {
        client: {
          Name: "70",
        },
        order: {
          Number: "91234",
          Itemnumber: "102",
          Date: "99",
        },
        shipping: {
          Date: "301223456",
        },
      },
    ];

    const ban = (evt: any) => {
      evt.target.classList.add("active");
    };
    return {
      list,
      ban,
    };
  },
  methods: {
    Chart() {
      this.Data = {
        Name: "123",
      };
    },
    Init() {
      this.Data = {};
    },
  },
});
