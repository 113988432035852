
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
  },
  components: {},
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-600");
    const dangerColor = getCSSVariableValue("--bs-danger");

    const options = {
      chart: {
        type: "bar",
        height: 480,
        stacked: true,
        stackType: "100%",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "過去七天機台狀態",
      },
      xaxis: {
        categories: ["4/1", "4/2", "4/3", "4/4", "4/5", "4/6", "4/7"],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "小時";
          },
        },
        x: {
          formatter: function (val) {
            return "日期：" + val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
      colors: [baseColor, secondaryColor, dangerColor],
    };

    const series = [
      {
        name: "開機",
        data: [11, 12, 11, 10, 4, 7, 20],
      },
      {
        name: "待機",
        data: [1, 2, 2, 10, 2, 12, 4],
      },
      {
        name: "關機",
        data: [12, 10, 11, 4, 18, 5, 0],
      },
    ];

    return {
      options,
      series,
    };
  },
});
